import { MainSubStructureModel, RenderByPermission, request } from '../../utils';
import type { BaseData } from '../../utils';
import type { FormInstance } from 'antd';
import { message, FormProps, Modal, Button, Switch } from 'antd';
import React from 'react';
import { action, observable, runInAction } from 'mobx';

export default class CategoryManagementModel {
  constructor() {
    this.mainSubStructureModel?.gridModel?.onQuery();
    this.mainSubStructureModelTwo?.gridModel?.onQuery();
  }

  @observable public modalOpen = false;

  @observable public secondLevelModalOpen = false;

  @observable public categoryFirstList = [];

  @observable public editId: string;

  @action
  public getCategoryFirst = async (id?: string) => {
    const req = await request<{ data: any[] }>({
      method: 'POST',
      url: '/quan/biz/category/parent',
      data: { ids: !id ? `1` : `1,${id}` },
    });
    this.categoryFirstList = (req?.data || []).map((v) => ({
      label: v.categoryName,
      value: v.id,
    }));
  };

  @action
  public setModalOpen = () => {
    this.modalOpen = !this.modalOpen;
    if (!this.modalOpen) {
      this.editId = undefined;
      this.formRef?.current?.resetFields();
    }
  };

  @action
  public setSecondLevelModalOpen = () => {
    this.secondLevelModalOpen = !this.secondLevelModalOpen;
    if (!this.secondLevelModalOpen) {
      this.editId = undefined;
      this.formRefTwo?.current?.resetFields();
    }
  };

  @action
  public onSubmit = () => {
    this.formRef.current.validateFields().then(async (data) => {
      const children = [];
      if (data.children.length) {
        data.children.forEach((id: any) => {
          children.push({ id });
        });
      }
      const req = await request<{ msg: string }>({
        method: 'POST',
        url: this.editId ? '/quan/biz/category/edit' : '/quan/biz/category/add',
        data: {
          ...data,
          children,
          categoryType: 0,
          id: this.editId,
        },
      });
      message.success(req?.msg);
      this.setModalOpen();
      this.mainSubStructureModel.gridModel.onQuery();
    });
  };

  @action
  public onSecondLevelSubmit = () => {
    this.formRefTwo.current.validateFields().then(async (data) => {
      const req = await request<{ msg: string }>({
        method: 'POST',
        url: this.editId ? '/quan/biz/category/edit' : '/quan/biz/category/add',
        data: {
          ...data,
          categorySequence: 0,
          categoryType: 1,
          id: this.editId,
        },
      });
      message.success(req?.msg);
      this.setSecondLevelModalOpen();
      this.mainSubStructureModelTwo.gridModel.onQuery();
    });
  };

  @action
  public onDelete = async (id, type: 0 | 1) => {
    if (!id) {
      return;
    }
    await request({
      method: 'POST',
      url: '/quan/biz/category/delete',
      data: { ids: `${id}` },
    });
    message.success('删除成功');
    if (type === 0) {
      this.mainSubStructureModel.gridModel.onQuery();
      return;
    }
    this.mainSubStructureModelTwo.gridModel.onQuery();
  };

  public formRef = React.createRef<FormInstance>();

  public formRefTwo = React.createRef<FormInstance>();

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '新增一级',
        permissionId: '2',
        handleClick: () => {
          this.getCategoryFirst();
          this.setModalOpen();
        },
      },
    ],
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    grid: {
      columns: [
        {
          key: 'billingOrderNo',
          name: '操作',
          width: 170,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');

            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    onClick={() => {
                      runInAction(() => {
                        this.editId = row.id;
                      });
                      this.formRef.current?.setFieldsValue({
                        categorySequence: row.categorySequence,
                        categoryName: row.categoryName,
                        children: row.children.map((v: any) => v.id as any),
                      });
                      this.getCategoryFirst(row.id);
                      this.setModalOpen();
                    }}
                    type="link"
                  >
                    编辑
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_4`}>
                  <Button
                    onClick={() => {
                      Modal.confirm({
                        title: '提示',
                        content: '确认删除该类别？',
                        onOk: () => {
                          this.onDelete(row.id, 0);
                        },
                      });
                    }}
                    type="link"
                  >
                    删除
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'categoryName',
          name: '一级类别',
          width: 200,
        },
        {
          key: 'secondLevelCategoryName',
          name: '二级类别',
          formatter: ({ row }) => {
            const secondLevelCategoryName = row?.children?.map((item) => item.categoryName);
            return <div>{secondLevelCategoryName?.join('、')}</div>;
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: false,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params): any => {
        const postParams = {
          ...params,
          filterParam: undefined,
        };
        return request<any>({
          url: '/quan/biz/category/list/type',
          method: 'GET',
          params: { categoryType: 0 },
        }).then((res) => ({ data: { list: res.data } }));
      },
    },
  });

  public mainSubStructureModelTwo: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '新增二级',
        permissionId: '18',
        handleClick: () => {
          this.setSecondLevelModalOpen();
        },
      },
    ],
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    grid: {
      columns: [
        {
          key: 'billingOrderNo',
          name: '操作',
          width: 170,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');

            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_19`}>
                  <Button
                    onClick={() => {
                      runInAction(() => {
                        this.editId = row.id;
                      });
                      this.formRefTwo.current?.setFieldsValue({ categoryName: row.categoryName });
                      this.setSecondLevelModalOpen();
                    }}
                    type="link"
                  >
                    编辑
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_20`}>
                  <Button
                    onClick={() => {
                      Modal.confirm({
                        title: '提示',
                        content: '确认删除该类别？',
                        onOk: () => {
                          this.onDelete(row.id, 1);
                        },
                      });
                    }}
                    type="link"
                  >
                    删除
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'categoryName',
          name: '二级类别',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: false,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params): any => {
        const postParams = {
          ...params,
          filterParam: undefined,
        };
        return request<any>({
          url: '/quan/biz/category/list/type',
          method: 'GET',
          params: { categoryType: 1 },
        }).then((res) => ({ data: { list: res.data } }));
      },
    },
  });
}
